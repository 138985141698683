@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800');
html,
body {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}
body {
    font-family: 'Montserrat';
    font-weight: 400;
    text-align: center;
    scroll-behavior: smooth;
    background-color: #fff !important;
}
body.modal-open,
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
    padding-right: 0px !important;
    overflow-y: auto;
}
.fs-100 {
    font-size: 100px;
}
.fs-75 {
    font-size: 75px;
}
.fs-40 {
    font-size: 40px;
}
.fs-26 {
    font-size: 26px;
}
.fs-24 {
    font-size: 24px;
}
.fs-22 {
    font-size: 22px;
}
.fs-20 {
    font-size: 20px;
}
.fs-19 {
    font-size: 19px;
}
.fs-18 {
    font-size: 18px;
}
.fs-17 {
    font-size: 17px;
}
.fs-16 {
    font-size: 16px;
}
.fs-15 {
    font-size: 15px;
}
.fs-14 {
    font-size: 14px;
}
.fs-13 {
    font-size: 13px;
}
.fs-12 {
     font-size: 12px;
 }
.fs-11 {
    font-size: 11px;
}
.fs-10 {
    font-size: 10px;
}
.fs-9 {
    font-size: 9px;
}
.fs-8 {
    font-size: 8px;
}
.isDesktop .fs-md-25 {
    font-size: 25px !important;
}
.isDesktop .fs-md-24 {
    font-size: 24px !important;
}
.isDesktop .fs-md-22 {
    font-size: 22px !important;
}
.isDesktop .fs-md-20 {
    font-size: 20px !important;
}
.isDesktop .fs-md-19 {
    font-size: 19px !important;
}
.isDesktop .fs-md-18 {
    font-size: 18px !important;
}
.isDesktop .fs-md-17 {
    font-size: 17px !important;
}
.isDesktop .fs-md-16 {
    font-size: 16px !important;
}
.isDesktop .fs-md-15 {
    font-size: 15px !important;
}
.isDesktop .fs-md-14 {
    font-size: 14px !important;
}
.isDesktop .fs-md-13 {
    font-size: 13px !important;
}
.isDesktop .fs-md-12 {
    font-size: 12px !important;
}
.isDesktop .fs-md-11 {
    font-size: 11px !important;
}
.isDesktop .fs-md-10 {
    font-size: 10px !important;
}
.isDesktop .fs-md-9 {
    font-size: 9px !important;
}
.isDesktop .fs-md-8 {
    font-size: 8px !important;
}
.isDesktop .lh-md-20 {
    line-height: 20px;
}
.mt-2-half {
    margin-top: 0.75rem !important;
}
.mb-2-half {
    margin-bottom: 0.75rem !important;
}
.me-2-half {
    margin-right: 0.75rem !important;
}
.ms-2-half {
     margin-left: 0.75rem !important;
 }
.isDesktop .mt-md-2-half {
    margin-top: 0.75rem !important;
}
.isDesktop .mb-md-2-half {
    margin-bottom: 0.75rem !important;
}
.isDesktop .me-md-2-half {
    margin-right: 0.75rem !important;
}
.isDesktop .ms-md-2-half {
    margin-left: 0.75rem !important;
}
.pt-2-half {
    padding-top: 0.75rem !important;
}
.pb-2-half {
    padding-bottom: 0.75rem !important;
}
.pe-2-half {
    padding-right: 0.75rem !important;
}
.ps-2-half {
    padding-left: 0.75rem !important;
}
.isDesktop .pt-md-2-half {
    padding-top: 0.75rem !important;
}
.isDesktop .pb-md-2-half {
    padding-bottom: 0.75rem !important;
}
.isDesktop .pe-md-2-half {
    padding-right: 0.75rem !important;
}
.isDesktop .ps-md-2-half {
    padding-left: 0.75rem !important;
}
.mt-n1 {
    margin-top: -0.25rem;
}
.mt-n2 {
    margin-top: -0.5rem;
}
.mt-n3 {
    margin-top: -1rem;
}
.mt-n4 {
    margin-top: -2rem;
}
.mt-n5 {
    margin-top: -3rem;
}
.isDesktop  .mt-md-n1 {
    margin-top: -0.25rem;
}
.isDesktop  .mt-md-n2 {
    margin-top: -0.5rem;
}
.isDesktop  .mt-md-n3 {
    margin-top: -1rem;
}
.isDesktop .mt-md-n4 {
    margin-top: -2rem;
}
.isDesktop  .mt-md-n5 {
    margin-top: -3rem;
}
.me-n1 {
    margin-right: -0.25rem;
}
.me-n2 {
    margin-right: -0.5rem;
}
.me-n3 {
    margin-right: -1rem;
}
.me-n4 {
    margin-right: -2rem;
}
.me-n5 {
    margin-right: -3rem;
}
.isDesktop  .me-md-n1 {
    margin-right: -0.25rem;
}
.isDesktop  .me-md-n2 {
    margin-right: -0.5rem;
}
.isDesktop  .me-md-n3 {
    margin-right: -1rem;
}
.isDesktop .me-md-n4 {
    margin-right: -2rem;
}
.isDesktop  .me-md-n5 {
    margin-right: -3rem;
}
.ms-n1 {
    margin-left: -0.25rem;
}
.ms-n2 {
    margin-left: -0.5rem;
}
.ms-n3 {
    margin-left: -1rem;
}
.ms-n4 {
    margin-left: -2rem;
}
.ms-n5 {
    margin-left: -3rem;
}
.isDesktop .ms-md-n1 {
    margin-left: -0.25rem;
}
.isDesktop .ms-md-n2 {
    margin-left: -0.5rem;
}
.isDesktop .ms-md-n3 {
    margin-left: -1rem;
}
.isDesktop .ms-md-n4 {
    margin-left: -2rem;
}
.isDesktop .ms-md-n5 {
    margin-left: -3rem;
}
.card {
    border-radius: 10px !important;
    overflow: hidden;
}
.btn, .alert, .profile-settings .form-control, .profile-settings .form-control, .modal-content {
    border-radius: 10px !important;
}
img {
    border-radius: 10px !important;
    width: 100%;
}
.form-control {
    border-color: #ddd !important;
}
.title-item {
    height: 74px;
    width: 100%;
    background-color: #fff;
}
.isMobile .title-item {
    height: 60px;
}
section.title {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: rgba(35, 35 ,35, 0.9);
    backdrop-filter: blur(8px);
    text-align: left;
}
.title .container-fluid {
    padding: 0 30px 0 20px;
    box-shadow: 0px 0px 6px 1px #a9a9a9;
}
.title .navbar {
    padding: 3px 16px;
}
.isMobile .title .navbar {
    padding: 4px 16px 6px 8px;
}
.title .navbar .navbar-brand {
    font-family: initial;
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 0;
    padding-top: 0;
    height: 68px;
    line-height: 60px;
}
.title .navbar .navbar-brand img {
    width: 285px;
}
.isMobile .title .navbar .navbar-brand img {
    width: 230px;
}
.title .navbar .navbar-nav .nav-link {
    color: rgba(255,255,255,.85);
    font-size: 15px;
    font-weight: 300;
    cursor: pointer;
}
.title .navbar .nav-item {
    padding: 0 15px;
}
.title .navbar .search-menu-button .nav-link {
    color: #fff;
    font-size: 22px;
    padding-right: 0;
}
.title .dropdown-menu {
    padding: 0px 10px 10px;
    background-color: #333;
    color: #FFFFFFD9;
    box-shadow: 0px 0px 3px 0px #444;
    border: none;
}
.title .dropdown-menu .dropdown-item {
    height: 50px;
    line-height: 40px;
    font-size: 14px;
    border-bottom: 1px solid #444;
    color: #fff;
    cursor: pointer;
}
.title .dropdown-menu .dropdown-item:hover {
    background-color: #444;
    color: #fff;
}
.title .dropdown-menu .dropdown-item:last-child {
    border: none;
}
.title .dropdown-menu .dropdown-divider {
    margin: 0;
    border-color: #444;
}
section.search .input-container {
    padding: 10px 10px 2px;
    border: 1px solid #ddd;
    min-height: 50px;
    border-radius: 10px;
}
section.search .input-container > label {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    top: -22px;
    font-size: 15px;
    left: 20px;
    background-color: white;
}
section.search .input-container > input, section.search .input-container > select  {
    border: none;
    margin: -32px 0 0;
    height: 56px;
    font-size: 15px;
}
.color-default {
    color: #212529!important;
}
.bg-light-gray {
    background-color: #f7f7f7;
}
section.search .input-container > input:focus, section.search .input-container > input:active,
section.search .input-container > select:focus, section.search .input-container > select:active,
input:active, select:active, select:focus, input:focus, input, select {
    outline: none !important;
    border-color: inherit !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
section.search .search-button-container button:focus, section.search .search-button-container button:active {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #343a40;
    background-color: #343a40;
}
section.search form button {
    height: 61.5px;
}
section.search form button i {
    font-size: 19px;
    vertical-align: middle;
    margin-right: 2px;
}
.br-10 {
    border-radius: 10px;
}
.footer {
    margin-left: 0px;
    background-color: rgba(35, 35 ,35, 0.9);
    backdrop-filter: blur(8px);
    color: aliceblue;
    font-size: 14px;
    box-shadow: 0px 0px 6px 1px #a9a9a9;
}
.footer .footer-container {
    padding-top: 30px;
    padding-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
}
.footer .footer-container .footer-item .footer-item-content {
    padding-left: 20px;
    border-left: 2px solid rgba(255, 255, 255, 0.1);
}
.footer .footer-container .footer-item .footer-item-content h4 {
    text-align: left;
    margin-left: 3px;
}
.footer .footer-container .footer-item .footer-item-content .list {
    list-style-type: none;
    text-align: left;
    font-size: 14px;
    padding: 5px 0 0;
}
.footer .footer-container .footer-item .social-icons {
    width: 24px;
    background: #fff;
    border-radius: 50% !important;
}
.footer .copyright {
    height: 50px;
    line-height: 50px;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 0;
    box-shadow: 0 0 4px 0 #a9a9a9;
}
.isMobile .footer .copyright {
    padding-bottom: 120px;
}
.footer .footer-container .footer-item .footer-item-content .list li {
    padding: 5px;
}
.footer .footer-container .list li .s7-call,
.footer .footer-container .list li .s7-mail,
.footer .footer-container .list li .s7-map-marker {
    padding-right: 10px;
}
.spacing {
  padding: 2%;
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.form-group {
  margin-bottom: 16px;
}
.app-container {
    display: block;
    background-color: #f1f1f18c;
    min-height: 500px;
}
.isDesktop .app-container {
    min-height: 700px;
}
.app-container.app-container-homepage, .app-container.app-container-homepage .homepage {
    min-height: 600px;
}
.isDesktop .app-container.app-container-homepage, .isDesktop .app-container.app-container-homepage .homepage {
    min-height: 800px;
}
.item-spacing {
    padding: 5px;
}
.authModal .btn-login, .report-modal .send-btn, .contacts-modal .send-btn {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 0.6rem;
}
.authModal .btn-login i {
    font-size: 15px;
    margin-right: 10px;
}
.authModal .modal-content, .authModal .card {
    border-radius: 10px !important;
} 
.authModal .form-floating > label {
    padding-left: 25px;
    font-size: 16px;
    margin-top: -2px;
}
.authModal .form-floating > input {
    padding-left: 23px;
    height: 55px;
    font-size: 15px;
    border-radius: 10px;
}
.authModal .label-link {
    margin-block: 0;
    font-size: 14px;
    color: #444;
    cursor: pointer;
    flex: 1;
    text-decoration: underline;
    text-underline-offset: 3px;
}
.authModal .info-text {
    font-size: 13px;
    color: #666;
    margin: 6px 0px 3px;
}
.item-icon-left {
    text-align: start;
    position: relative;
    padding: 16px 16px 16px 84px;
    max-width: 350px;
    min-height: 90px;
}
.item-icon-left .icon {
    font-size: 60px;
    position: absolute;
    left: 10px;
}
.item-icon-left h5 {
    font-weight: 400;
    margin-bottom: 3px;
}
.item-icon-left p {
    margin: 0;
    font-size: 14px;
}
.profile-tabs .profile-tab {
    padding: 10px 0px;
    font-size: 16px;
    cursor: pointer;
}
.profile-tab-content, .page-content {
    background-color: #fff;
    min-height: 600px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
}
.section-content {
    background-color: #fff;
    min-height: 200px;
    padding-bottom: 20px;
    border-radius: 10px;
}
.profile-classifieds .classifieds-count {
    margin-left: 5px;
}
.profile-classifieds .classifieds-tabs, .profile-messages .settings-tabs { 
    margin-top: 8px;
}
.profile-classifieds .classifieds-tabs .classifieds-tab, .profile-messages .settings-tabs .settings-tab {
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    width: auto;
    padding: 5px 20px;
    color: #666;
}
.profile-classifieds .classifieds-tabs .classifieds-tab.active , .profile-messages .settings-tabs .settings-tab.active {
    color: #212529;
}
.profile-classifieds .classifieds-filters .btn {
    font-size: 13px;
}
.profile-classifieds .classifieds-filters i {
    margin-right: 5px;
    font-size: 18px;
    vertical-align: middle;
}
.profile-classifieds .classifieds-filters .badge {
    font-weight: 500;
    margin-left: 4px;
}
.classified-list-item {
    padding: 12px 8px;
    border-radius: 10px;
    background-color: #e6e6e687;
}
.isMobile .classified-list-item {
    padding: 8px 12px;
}
.isDesktop .classified-list-item {
    padding: 10px 4px;
    box-shadow: 0 0 2px #aaa;
}
.isDesktop .classified-list-item .classified-info-content {
    height: 180px;
}
.classified-list-item .classified-image img {
    border-radius: 10px;
    max-height: 210px;
    object-fit: cover;
    box-shadow: 0 0 1px #999;
}
.classified-list-item .classified-description {
    overflow: hidden;
    max-height: 40px;
}
.isMobile .classified-list-item .classified-description {
    max-height: 70px;
}
.btn-white {
    background-color: #fff;
    border-color: #fff;
}
.btn-white:hover, .btn-white:active {
    background-color: #f1f1f1;
    border-color: #f1f1f1;
}
.classified-list-item .classified-meta-item i {
    margin-right: 10px;
    vertical-align: middle;
}
.classified-list-item .classified-meta-item .form-switch, .create-classified .form-switch {
    padding-left: 3em;
}
.classified-list-item .classified-meta-item .form-switch input, .create-classified .form-switch input {
    height: 1.2em;
    width: 2.5em;
    margin-left: -3em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='red'/%3e%3c/svg%3e");
    border-color: rgba(0,0,0,.25);
}
.classified-list-item .classified-meta-item .form-switch input:focus, .messages-content .messages-container .compose-input:focus,
.create-classified .form-switch input:focus {
    outline: none;
    box-shadow: none;
}
.classified-list-item .classified-meta-item .form-switch input:checked, .create-classified .form-switch input:checked {
    background-color: red;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
    border-color: #ff0000;
}
.classified-list-item .classifieds-action {
    border: 1px solid #dddddd8a;
    border-radius: 6px !important;
}
.isMobile .classified-list-item .classifieds-action {
    margin: 0 4px !important;
    padding: 6px 10px;
    font-size: 13px;
}
.br-4 {
    border-radius: 4px !important;
}
.profile-payments .filters-bar .date-picker {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    padding: 6px 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}
.fw-500 {
    font-weight: 500 !important;
}
.profile-payments .payments-lists .bg-head {
    background-color: #ddd6;
}
.list-group-item.active {
    color: #212529;
    background-color: #d9d9d9ad;
    border-color: #d9d9d9ad;
}
.profile-settings .settings-content {
    background-color: #f7f7f7;
    padding-top: 20px;
    border-radius: 10px;
    padding-bottom: 20px;
}
.profile-settings .tab-content .tab-pane, .profile-settings .accordion .accordion-collapse {
    min-height: 400px;
}
.btn-gray {
    background-color: #d9d9d9ad;
    border-color: #d9d9d9ad;
}
.container.plans {
    padding-bottom: 60px;
}
.card-plans .card-body {
    height: 430px;
}
.card-plans.plan-basic .card-title, .card-plans.plan-basic .card-footer {
    background: linear-gradient(#8b8181, #d9d9d9);
    color: #fff;
}
.card-plans.plan-standard .card-title, .card-plans.plan-standard .card-footer {
    background: linear-gradient(#9b9b70, #CCCCB2);
    color: #fff;
}
.card-plans.plan-premium .card-title, .card-plans.plan-premium .card-footer {
    background: linear-gradient(#000000, #777777);
    color: #fff;
}
.card-plans.plan-diamond .card-title, .card-plans.plan-diamond .card-footer {
    background: linear-gradient(#4ca1af, #c4e0e5);
    color: #fff;
}
.bullet-list-item {
    list-style-type: disc;
}
.mt-6 {
    margin-top: 6rem;
}
.mx--4{
    margin-left: -16px;
    margin-right: -16px;
}
.isMobile .mb-5 {
    margin-bottom: 15px !important;
}
.classifieds-total-container .badge {
    background-color: #d5d5d58c !important;
    width: 52px;
    margin: 0 5px;
    font-weight: 300;
    color: #222 !important;
    font-size: 14px;
    cursor: pointer;
}
.classifieds-total-container .badge.badge-active {
    background-color: #adacac !important;
}
.create-classified input.input-field, .create-classified select, .create-classified textarea,
.report-modal input.input-field, .report-modal textarea, .contacts-modal input.input-field, .contacts-modal textarea {
    border-radius: 10px !important;
    border-color: #ddd !important;
    background-color: #dddddd4f !important;
    height: 50px;
    color: #666;
}
.create-classified textarea {
    height: 240px;
    resize: none;
}
.report-modal textarea, .contacts-modal textarea {
    height: 200px;
    resize: none;
}
.create-classified input:focus, .create-classified select:focus, .create-classified textarea:focus, .create-classified button:focus,
.report-modal input:focus, .report-modal textarea:focus, .contacts-modal input:focus, .contacts-modal textarea:focus {
    outline: none;
    box-shadow: none;
    background-color: #dddddd4f;
    border-color: #ccc;
}
.create-classified input::-webkit-input-placeholder, .create-classified textarea::-webkit-input-placeholder {
    color: #aaa;
}
.create-classified input:-moz-placeholder, .create-classified textarea:-moz-placeholder {
    color: #aaa;
    opacity: 1;
}
.create-classified input::-moz-placeholder, .create-classified textarea::-moz-placeholder {
    color: #aaa;
    opacity: 1;
}
.create-classified input:-ms-input-placeholder, .create-classified textarea:-ms-input-placeholder {
    color: #aaa;
}
.create-classified input::-ms-input-placeholder, .create-classified textarea::-ms-input-placeholder {
    color: #aaa;
}
.create-classified input::placeholder, .create-classified textarea::placeholder {
    color: #aaa;
}
.create-classified .images-container {
    max-width: 780px;
    display: flex;
    flex-wrap: wrap;
}
.create-classified .image-container {
    position: relative;
    height: 140px;
    width: 240px;
    padding: 0;
    background-color: #dddddd4f;
    margin: 5px;
    border: 1px solid #cccccc47;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
}
.isMobile .create-classified .image-container {
    height: 60px;
    width: 102px;
}
@media screen and (max-width: 349px) {
    .create-classified .image-container {
        height: 48px !important;
        width: 82px !important;
    }
    .create-classified .image-container .image-content {
        font-size: 9px !important;;
    }
    .create-classified .image-container .image-content i {
        font-size: 12px !important;;
    }
}
@media screen and (min-width: 350px) and (max-width: 369px) {
    .create-classified .image-container {
        height: 55px !important;
        width: 96px !important;
    }
}
@media screen and (min-width: 370px) and (max-width: 502px) {
    .create-classified .image-container {
        height: 60px !important;
        width: 102px !important;
    }
}
.create-classified .image-container .image-content {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 50%;
    background-color: #dddddd4f;
}
.create-classified .image-container .image-content i {
    font-size: 16px;
}
.isMobile .create-classified .image-container .image-content {
    font-size: 10px;
}
.isMobile .create-classified .image-container .image-content i {
    font-size: 14px;
}
.create-classified .details button {
    height: 40px;
}
.create-classified .details button.active {
    background-color: #444;
    color: #fff;
}
.create-classified input.form-check-input-checkbox {
    width: 50px;
    height: 22px;
    cursor: pointer;
}
.create-classified input.form-check-input:focus:not(:checked){
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='red'/%3e%3c/svg%3e");
}
.create-classified .form-radio, .create-classified .form-radio label {
    cursor: pointer;
}
.create-classified input.form-check-input:checked[type=radio]:checked {
    background-color: #ff0000;
    border-color: #ff0000;
}
.create-classified .submit-buttons {
    height: 50px;
    line-height: 40px !important;
}
.create-classified .submit-buttons.btn-danger:focus {
    background-color: #dc3545eb;
}
.modal-fullscreen {
    width: 96%;
    height: auto;
    margin: 2%;
}
.modal-fullscreen .modal-content {
    border-radius: 4px;
}
.isDesktop .gx-md-6 {
    --bs-gutter-x: 4rem;
}
.classified-content .carosel-container {
    max-height: 550px;
    height: 550px;
}
.isMobile .classified-content .carosel-container {
    max-height: 280px;
    height: 280px;
}
.carousel-control-next, .carousel-control-prev {
    width: 60px;
    opacity: 0.6;
}
.isMobile .carousel-control-next, .isMobile .carousel-control-prev {
    width: 45px;
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    opacity: 0.6;
}
.object-fit-contain {
    object-fit: contain;
}
.slider-button {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0 0 3px 1px #ccc;
    justify-content: center;
    border: none;
    background-color: rgb(0,0,0);
    border-radius: 50%;
}
.isMobile .slider-button {
    height: 45px;
    width: 45px;
}
.isMobile .carousel-control-next-icon, .isMobile .carousel-control-prev-icon {
    width: 24px;
    height: 24px;
}
.transparency-black {
    background-color: rgba(0,0,0,0.3) !important;
}
.transparency-black-cover {
    background-color: rgba(0,0,0,0.5) !important;
}
.avatar {
    border-radius: 50px !important;
    border: 1px solid #e9e9e9;
}
.avatar-container .avatar {
    height: 66px;
    width: 66px;
}
.avatar-container .avatar.big {
    height: 90px;
    width: 90px;
}
.isDesktop .avatar-container .avatar.big {
    height: 100px;
    width: 100px;
}
.bottom-20 {
    bottom: 20px;
}
.cursor-pointer {
    cursor: pointer;
}
.btn-light-dark {
    border-color: #444;
    background: #444;
    color: #fff;
}
.btn-muted {
    border-color: #aaa;
    background: #ccc;
    color: #333;
}
.btn-light-dark:active, .btn-light-dark:focus, .btn-light-dark:hover {
    outline: none !important;
    border-color: inherit !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff !important;
}
.classified-content .btn-light-dark {
    height: 42px;
}
.classified-content a.btn-light-dark {
    line-height: 30px;
}
.classified-content .custom-badge {
    background-color: #f1f1f18c;
    padding: 3px 9px;
    border-radius: 6px;
    border: 1px solid #ddd;
    display: inline-block;
}
.mapboxgl-map {
    pointer-events: none;
    border-radius: 4px;
}
.mapboxgl-canvas-container {
    height: 200px;
}
.classified-thumb-container {
    flex: 1 0 auto;
    max-width: 260px;
    min-height: 260px;
    margin-right: 15px;
}
a.classified-thumb, a.profile-tab, a.main-categories {
    text-decoration: none;
    color: #212529;
}
.isDesktop a.classified-thumb > div:hover {
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: 0 0 3px 1px #ddd;
    border-radius: 4px;
}
.classified-thumb-container .classified-thumb .card .card-img-top {
    height: 75%;
    width: 100%;
    object-fit: contain;
}
.w-85 {
    width: 85%;
}
.isDesktop .w-md-100 {
    width: 100%;
}
.isMobile section.title {
    position: absolute;
}
.isMobile #navbarDesktopMenu {
    display: none;
}
.isMobile section.search .input-container {
    height: 56px;
}
.isMobile section.search .input-container > label {
    font-size: 14px;
}
.isMobile section.search .input-container > input {
    padding: 6px 6px;
    height: 52px;
    font-size: 14px;
}
.isMobile section.search form .search-button-container {
    margin-left: -2px;
    height: 56px;
}
.isMobile section.search form .search-button-container button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}
.isMobile .title .container-fluid {
    padding: 0;
}
.isMobile .title .navbar .navbar-brand {
    height: 50px;
    line-height: 40px;
}
.isMobile .homepage .main-categories {
    padding: 3px;
}
.homepage .categories-content .card {
    height: 245px;
}
.isMobile .homepage .categories-content .card {
    height: 135px;
}
.isMobile .homepage .categories-content .card .card-body {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
}
.isDesktop. .footer-navbar {
    display: none;
}
.isMobile .footer-navbar .navbar {
    z-index: 99;
    background-color: #232323;
    box-shadow: 0 0 6px 1px #a9a9a9;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.isMobile .profile-content {
    padding: 0 12px !important;
}
.col-20 {
    flex: 0 0 auto;
    width: 20%;
}
.col-125 {
    flex: 0 0 auto;
    width: 12.5%;
}
@media (min-width: 768px){
    .col-md-20 {
        flex: 0 0 auto;
        width: 20%;
    }
    .col-md-125 {
        flex: 0 0 auto;
        width: 12.5%;
    }
}
.bs-gutter-x-0 {
    --bs-gutter-x: 0
}
.bs-gutter-x-1 {
    --bs-gutter-x: 1rem;
}
.isMobile .mobile-hidden, .isDesktop .desktop-hidden {
    display: none;
}
.isMobile .profile-content .user-information {
    padding: 0;
}
.isMobile .profile-content .user-information > div {
    float: left !important;
}
.profile-tabs .profile-tab {
    color: #222;
    height: 36px;
    width: 200px;
    position: relative;
    background: #f1f1f18c;
    border-radius: 25px;
    line-height: 17px;
    box-shadow: 0 0 2px #7c7c7c;
    font-size: 14px;
    margin: 0 4px;
    white-space: nowrap;
}
.isMobile .profile-tabs .profile-tab {
    width: 120px;
    font-size: 13px;
}
.isMobile .profile-tabs .profile-tab div {
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.isMobile .profile-tab-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.isMobile .profile-classifieds .classifieds-tabs {
    margin: 8px 0 0;
}
.classifieds-tabs .classifieds-tabs-container {
    padding: 4px 8px 16px;
}
.isMobile .classifieds-tabs .classifieds-tabs-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll hidden;
}
.classifieds-tabs .classifieds-tabs-container .classifieds-tab {
    background: #f1f1f18c;
    border-radius: 25px;
    height: 30px;
    line-height: 22px;
    box-shadow: 0 0 2px #7c7c7c;
    font-size: 13px;
    padding: 4px 20px;
    margin: 0 4px;
    white-space: nowrap;
}
.isMobile .classifieds-tabs .classifieds-tabs-container .classifieds-tab {
    padding: 4px 12px;
    font-size: 12px;
}
.profile-tabs .profile-tab.active, .profile-classifieds .classifieds-tabs .classifieds-tab.active {
    background: #444 ;
    color: #fff;
}
.isMobile .classifieds-tabs .classifieds-filters {
    margin-top: 4px;
}
.isMobile .classifieds-tabs .classifieds-filters button {
    font-size: 11px;
}
.isMobile .classifieds-tabs .classifieds-filters button i {
    font-size: 13px;
}
.isMobile .profile-payments, .isMobile .profile-settings {
    padding: 0!important;
}
.isMobile .profile-settings .accordion-item {
    border-bottom: 1px solid #d2d2d2e6;
}
.isMobile .profile-settings .accordion-header .accordion-button {
    background-color: #ddd6;
}
.isMobile .profile-settings .accordion-header .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}
.isMobile .profile-settings .accordion-header .accordion-button:not(.collapsed){
    color: #212529;
}
.isMobile .classified-thumb-list .classified-thumb-list-container {
    padding-bottom: 10px;
}
.isMobile .classified-thumb-container {
    max-width: 130px;
    min-height: 160px;
    margin-right: 6px;
}
.isMobile .classified-thumb .card .card-body .text-muted {
    font-size: 8px;
}
.carousel-indicators [data-bs-target] {
    border-radius: 50%;
    border: none !important;
    height: 13px !important;
    width: 13px !important;
    opacity: .6;
    transition: opacity .6s ease;
    box-shadow: 0 0 0 1px #aaa;
    background-color: #fff !important;
}
.isMobile .carousel-indicators [data-bs-target] {
    height: 10px !important;
    width: 10px !important;
}
.carousel-indicators .active {
    opacity: 1;
}
.set-empty {
    position: absolute;
    right: 5px;
    top: calc(100%/2 - 17px);
    font-size: 25px;
    color: #444;
    cursor: pointer;
}
.select-search-field .select-search-field-list {
    position: absolute;
    height: auto;
    max-height: 220px;
    background-color: #fff;
    width: calc(100% + 2px);
    left: -1px;
    margin-top: -4px;
    color: #666;
    border: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
    overflow: hidden scroll;
    z-index: 111;
}
.select-search-field .select-search-field-list .select-search-option:hover {
    cursor: pointer;
    background-color: #f1f1f1;
}
.select-search-field .select-search-field-list .select-search-option.selected {
    background-color: #f1f1f1;
}
.pagination .page-link {
    padding: 0;
    width: 40px;
    color: #222;
    line-height: 40px;
    height: 40px;
    font-size: 15px;
    cursor: pointer;
}
.pagination .page-item:last-child .page-link {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}
.pagination .page-item:first-child .page-link {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}
.pagination .page-item .page-link.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.pagination .page-item .page-link.selected {
    background-color: #ececec;
    pointer-events: none;
}
.pagination .page-item .page-link:hover {
    background-color: #ececec21;
}
@media screen and (min-width: 1560px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1460px;
    }
}
@media screen and (min-width: 1760px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1620px;
    }
}
.profile-mobile-messages {
    min-height: 500px;
    background-color: #ffffff;
}
.isMobile .classified-content .classified-info-container, .profile-mobile-messages .message-users-list {
    box-shadow: 0px -3px 3px #cacaca;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: -10px;
    z-index: 99;
}
.classified-content .classified-category{
    margin-top: 2px;
}
.ldsRoller-container {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes ldsRoller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.ldsRoller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.ldsRoller div {
    animation: ldsRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.ldsRoller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    margin: -4px 0 0 -4px;
}
.ldsRoller.ldsRoller-white div:after{
    background: #fff;
}
.ldsRoller div:nth-child(1) {
    animation-delay: -0.036s;
}
.ldsRoller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.ldsRoller div:nth-child(2) {
    animation-delay: -0.072s;
}
.ldsRoller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.ldsRoller div:nth-child(3) {
    animation-delay: -0.108s;
}
.ldsRoller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.ldsRoller div:nth-child(4) {
    animation-delay: -0.144s;
}
.ldsRoller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.ldsRoller div:nth-child(5) {
    animation-delay: -0.18s;
}
.ldsRoller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.ldsRoller div:nth-child(6) {
    animation-delay: -0.216s;
}
.ldsRoller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.ldsRoller div:nth-child(7) {
    animation-delay: -0.252s;
}
.ldsRoller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.ldsRoller div:nth-child(8) {
    animation-delay: -0.288s;
}
.ldsRoller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

.ldsRoller-container {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes ldsRoller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.mini-ldsRoller {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
}
.mini-ldsRoller div {
    animation: ldsRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 12px 12px;
}
.mini-ldsRoller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: #000;
    margin: -1px 0 0 -1px;
}
.mini-ldsRoller.mini-ldsRoller-white div:after{
    background: #fff;
}
.mini-ldsRoller div:nth-child(1) {
    animation-delay: -0.036s;
}
.mini-ldsRoller div:nth-child(1):after {
    top: 18px;
    left: 20px;
}
.mini-ldsRoller div:nth-child(2) {
    animation-delay: -0.072s;
}
.mini-ldsRoller div:nth-child(2):after {
    top: 20px;
    left: 17px;
}
.mini-ldsRoller div:nth-child(3) {
    animation-delay: -0.108s;
}
.mini-ldsRoller div:nth-child(3):after {
    top: 21px;
    left: 14px;
}
.mini-ldsRoller div:nth-child(4) {
    animation-delay: -0.144s;
}
.mini-ldsRoller div:nth-child(4):after {
    top: 21px;
    left: 11px;
}
.mini-ldsRoller div:nth-child(5) {
    animation-delay: -0.18s;
}
.mini-ldsRoller div:nth-child(5):after {
    top: 20px;
    left: 8px;
}
.mini-ldsRoller div:nth-child(6) {
    animation-delay: -0.216s;
}
.mini-ldsRoller div:nth-child(6):after {
    top: 18px;
    left: 5px;
}

.text-muted {
    color: #444 !important;
}
.z-index-99 {
    z-index: 99;
}
.images-dialog .modal-dialog {
    width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
}
.images-dialog .modal-dialog .modal-content {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.85);
}
.images-dialog .carousel-control-next, .images-dialog .carousel-control-prev {
    opacity: 1;
}
.images-dialog .carousel-control-prev {
    left: 3px;
}
.images-dialog .carousel-control-next {
    right: 3px;
}
.images-dialog .slider-button {
    box-shadow: 0 0 3px 1px #aaa;
    background-color: rgba(0,0,0,0.75);
}
.search-results .empty-results {
    height: 400px;
    color: #aaa;
}
.search-results .empty-results i {
    font-size: 100px;
}
.message-tabs .message-badge {
    background: #f1f1f18c;
    border-radius: 25px;
    line-height: 18px;
    box-shadow: 0 0 2px #7c7c7c;
    font-size: 12px;
    padding: 4px 12px;
    margin: 0 4px;
}
.message-tabs .message-badge.active {
    background: #fff !important;
}
.messages-content .avatar {
    width: 40px !important;
}
.messages-content .item-image {
    width: 40px !important;
    height: 40px !important;
    object-fit: cover;
}
.messages-content .message-content .message {
    max-width: 80%;
}
.messages-content .message-content.message-me {
    justify-content: end;
}
.messages-content .message-content.message-other {
    justify-content: start;
}
.messages-content .message-content.message-me .message {
    background: #e0d5ff;
    border-radius: 15px;
}
.messages-content .message-content.message-other .message {
    background: #f1f1f1;
    border-radius: 15px;
}
.isMobile section.search .input-border-radius-right-none .input-container {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.messages-content .compose-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    height: 45px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #ddd;
}
.isDesktop .messages-content {
    height: 100%;
}
.isDesktop .messages-content .compose-container {
    border: none;
    border-top: 1px solid #ddd;
    border-radius: 0;
}
.messages-content .compose-container input {
    border: none;
}
.messages-content .compose-container input:disabled, .messages-content .compose-container button:disabled {
    background: #eaeaea !important;
}
.isMobile .app-container-chat {
    position: absolute;
    width: 100%;
    height: calc(100% - 58px);
    top: 58px;
    left: 0;
}
.isMobile .messages-content {
    width: 100%;
    height: 100%
}
.messages-content .messages-container {
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
}
.messages-content .messages-container .messages-list {
    min-height: 50px;
    max-height: calc(100% - 158px);
    margin-bottom: 50px;
    overflow-y: scroll;
}
.messages-content.inactive-chat .messages-container .messages-list {
    max-height: calc(100% - 205px);
}
.profile-desktop-messages .messages-content .messages-container .messages-list {
    max-height: calc(78vh - 185px);
}
.profile-desktop-messages .messages-content.inactive-chat .messages-container .messages-list {
    max-height: calc(78vh - 232px);
}
.vh-78 {
    height: 78vh;
}
.border-right {
    border-right: 1px solid #ddd;
}
.isDesktop .position-md-relative {
    position: relative;
}
.isDesktop .position-md-absolute {
    position: absolute;
}
.profile-settings .form-control, .profile-settings .form-control {
    height: 44px;
}
.Toastify__toast {
    font-family: 'Montserrat' !important;
    border-radius: 10px !important;
}
.Toastify__toast .Toastify__toast-body {
    font-size: 14px;
    font-weight: 400;
}
.Toastify__toast .Toastify__toast-body .Toastify__toast-icon {
    margin-bottom: 0 !important;
}
.Toastify__toast .Toastify__close-button {
    margin-top: -5px;
}
.error-dialog .modal-dialog {
    max-width: 420px;
}
.error-dialog .modal-header {
    padding: 12px !important;
}
.error-dialog .modal-body {
    padding: 12px 16px 20px;
}
.error-dialog .modal-footer {
    border: none;
    padding: 0 12px 8px;
    justify-content: center;
}
.error-dialog .modal-footer .btn {
    margin: 0 10px;
    padding: 6px 20px;
}
.isDesktop .profile-classifieds .profile-classifieds-content {
    padding: 0 18px;
}
.isMobile .profile-classifieds .profile-classifieds-content {
    box-shadow: 0 0 2px #aaa;
    border-radius: 10px;
}
.create-classified .image-container .image-remove-container {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    padding-top: 1px;
}
.isMobile .create-classified .image-container .image-remove-container {
    top: 2px;
    right: 2px;
    width: 17px;
    height: 17px;
    font-size: 15px;
    padding-top: 1px;
}
.blur-5 {
    filter: blur(5px);
}
.classified-thumb .favorite-icon .icon-fill {
    right: 1px;
    top: 2px;
}
.isMobile .classified-thumb .favorite-icon .icon-fill {
    right: 1px;
    top: 1px;
}
.start-75 {
    left: 75%;
}
.title .navbar .search-menu-button .search-active-filter-indicator {
    margin-right: -9px !important;
    margin-top: 10px !important;
}
.search .search-active-additional-filter-indicator {
    margin-left: 7px !important;
    margin-top: 4px !important;
}
.isMobile .messages-content .negotiable {
    font-size: 10px;
    text-align: center;
    font-weight: 300;
    margin-top: 5px;
}
.pointer-events-none {
    pointer-events: none;
}
.pointer-events-all {
    pointer-events: all;
}
.app-container-homepage {
    background-color: #fff !important;
}
.footer-item-content .list li a, .terms-accept-container a {
    text-decoration: none;
}
.page-404 {
    background: #343a40;
    color: #fff;
}
.page-404 p {
    color: #fff !important;
    max-width: 500px;
}
.page-404 img {
    max-width: 600px;
}
.page-404 .button-home {
    width: 260px !important;
    height: 45px;
    line-height: 34px;
    border-color: #fff;
}
.login-page {
    width: 100vw;
}
.isDesktop .login-page {
    width: 500px;
}
.isDesktop .vh-md-100 {
    height: 100vh;
}
.text-light-dark {
    color: #444 !important;
}
.classified-list-item .classified-image .classified-badges-list {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px 20px;
}
.classified-list-item .classified-image .classified-expired-badge {
    font-size: 12px;
    padding: 6px;
    font-weight: 400;
    box-shadow: 0 0 2px #ccc;
    background: rgba(0,0,0, 0.6);
    color: #ccc;
    margin: 0 2px;
}
.isDesktop .w-md-75 {
    width: 75%;
}
.btn-stats {
    background: #f2f2f2;
    border: 1px solid #ddd;
}
.moderate-comment-area {
    height: 150px;
}
.moderate-comment-send-btn {
    height: 40px;
}
.quickSign .modal-content {
    background: #343a40;
    color: #fff;
}
.quickSign .btn-login {
    height: 44px;
    font-size: 14px;
    background-color: #dc3545;
    border-color: #dc3545;
}
.quickSign .close-button {
    color: #fff;
    padding: 0 5px;
}